import { default as _91_46_46_46all_937YnFXanHiRMeta } from "C:/project-3.7/office-web/official-web/pages/[...all].vue?macro=true";
import { default as about8sR7w3HmdSMeta } from "C:/project-3.7/office-web/official-web/pages/about.vue?macro=true";
import { default as contact2ZrgILLl4kMeta } from "C:/project-3.7/office-web/official-web/pages/contact.vue?macro=true";
import { default as _91id_93WBIhA6Qio5Meta } from "C:/project-3.7/office-web/official-web/pages/experience/[id].vue?macro=true";
import { default as indexkcl4rcXOPmMeta } from "C:/project-3.7/office-web/official-web/pages/index.vue?macro=true";
import { default as loginV5nVmRtLnbMeta } from "C:/project-3.7/office-web/official-web/pages/login.vue?macro=true";
import { default as _91id_93d64xY1rGrbMeta } from "C:/project-3.7/office-web/official-web/pages/product/[id].vue?macro=true";
export default [
  {
    name: _91_46_46_46all_937YnFXanHiRMeta?.name ?? "all",
    path: _91_46_46_46all_937YnFXanHiRMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_937YnFXanHiRMeta || {},
    alias: _91_46_46_46all_937YnFXanHiRMeta?.alias || [],
    redirect: _91_46_46_46all_937YnFXanHiRMeta?.redirect || undefined,
    component: () => import("C:/project-3.7/office-web/official-web/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: about8sR7w3HmdSMeta?.name ?? "about",
    path: about8sR7w3HmdSMeta?.path ?? "/about",
    meta: about8sR7w3HmdSMeta || {},
    alias: about8sR7w3HmdSMeta?.alias || [],
    redirect: about8sR7w3HmdSMeta?.redirect || undefined,
    component: () => import("C:/project-3.7/office-web/official-web/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contact2ZrgILLl4kMeta?.name ?? "contact",
    path: contact2ZrgILLl4kMeta?.path ?? "/contact",
    meta: contact2ZrgILLl4kMeta || {},
    alias: contact2ZrgILLl4kMeta?.alias || [],
    redirect: contact2ZrgILLl4kMeta?.redirect || undefined,
    component: () => import("C:/project-3.7/office-web/official-web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WBIhA6Qio5Meta?.name ?? "experience-id",
    path: _91id_93WBIhA6Qio5Meta?.path ?? "/experience/:id()",
    meta: _91id_93WBIhA6Qio5Meta || {},
    alias: _91id_93WBIhA6Qio5Meta?.alias || [],
    redirect: _91id_93WBIhA6Qio5Meta?.redirect || undefined,
    component: () => import("C:/project-3.7/office-web/official-web/pages/experience/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkcl4rcXOPmMeta?.name ?? "index",
    path: indexkcl4rcXOPmMeta?.path ?? "/",
    meta: indexkcl4rcXOPmMeta || {},
    alias: indexkcl4rcXOPmMeta?.alias || [],
    redirect: indexkcl4rcXOPmMeta?.redirect || undefined,
    component: () => import("C:/project-3.7/office-web/official-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginV5nVmRtLnbMeta?.name ?? "login",
    path: loginV5nVmRtLnbMeta?.path ?? "/login",
    meta: loginV5nVmRtLnbMeta || {},
    alias: loginV5nVmRtLnbMeta?.alias || [],
    redirect: loginV5nVmRtLnbMeta?.redirect || undefined,
    component: () => import("C:/project-3.7/office-web/official-web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93d64xY1rGrbMeta?.name ?? "product-id",
    path: _91id_93d64xY1rGrbMeta?.path ?? "/product/:id()",
    meta: _91id_93d64xY1rGrbMeta || {},
    alias: _91id_93d64xY1rGrbMeta?.alias || [],
    redirect: _91id_93d64xY1rGrbMeta?.redirect || undefined,
    component: () => import("C:/project-3.7/office-web/official-web/pages/product/[id].vue").then(m => m.default || m)
  }
]