import revive_payload_client_DddOonemol from "C:/project-3.7/office-web/official-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_sass@1.63.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_5HJ7jxFGL3 from "C:/project-3.7/office-web/official-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_sass@1.63.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VO85YwAqVG from "C:/project-3.7/office-web/official-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_sass@1.63.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "C:/project-3.7/office-web/official-web/.nuxt/components.plugin.mjs";
import unhead_o1DiEzKfwg from "C:/project-3.7/office-web/official-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_sass@1.63.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_oKzTp4I6ct from "C:/project-3.7/office-web/official-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_sass@1.63.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_xPbRnWSPwZ from "C:/project-3.7/office-web/official-web/node_modules/.pnpm/nuxt@3.6.2_@types+node@18.16.19_sass@1.63.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import naive_ui_cjnU5TlW5h from "C:/project-3.7/office-web/official-web/plugins/naive-ui.ts";
import svgicon_client_9ZXHQx4ITY from "C:/project-3.7/office-web/official-web/plugins/svgicon.client.ts";
export default [
  revive_payload_client_DddOonemol,
  router_5HJ7jxFGL3,
  payload_client_VO85YwAqVG,
  components_plugin_KR1HBZs4kY,
  unhead_o1DiEzKfwg,
  prefetch_client_oKzTp4I6ct,
  chunk_reload_client_xPbRnWSPwZ,
  naive_ui_cjnU5TlW5h,
  svgicon_client_9ZXHQx4ITY
]