<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <nuxt-layout>
      <!-- 显示导航进度 -->
      <nuxt-loading-indicator />
      <nuxt-page />
    </nuxt-layout>
  </n-config-provider>
</template>

<script lang="ts" setup>
import { GlobalThemeOverrides } from 'naive-ui'

const color1 = '#0E61FE'
const textColor = '#333'
const themeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: color1,
    primaryColorHover: color1,
    textColorBase: textColor,
    textColor1: textColor,
    textColor2: textColor,
    textColor3: textColor,
    borderRadius: '2px'
  }
}
</script>
